import dayjs from 'dayjs'
import DatePicker from '@/components/common/date-picker/index.vue'

/**
 * 选择查询业绩的时间范围
 * 以月份为单位
 */
export default {
  name: 'month-picker',

  components: {
    DatePicker
  },

  model: {
    prop: 'value',
    event: 'confirm'
  },

  props: {
    value: { // 传入日期值
      required: true,
      validator (value) {
        return !value || Object.prototype.toString.call(value) === '[object Date]'
      }
    },
    minDate: {
      required: true,
      default: () => new Date(2019, 6) // 最小日期：2019-07
    }
  },

  data () {
    return {}
  },

  methods: {
    formatter,

    date2Str (date) {
      date = dayjs(date)
      const [year, month] = [date.get('year'), date.get('month') + 1]
      return `${year}年${month}月`
    },

    showMonthPicker () {
      this.$refs['monthPicker'].show()
    }
  }
}

function formatter (type, value) {
  if (type === 'year') {
    return `${value}年`
  } else if (type === 'month') {
    return `${Number(value)}月`
  }
  return value
}

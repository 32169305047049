import { getRebateActivity } from '@/service/rebate/novNewRebate';

/**
 * 选择查询业绩的时间范围
 * 以月份为单位
 */
export default {
    name: 'activity-picker',

    components: {},

    model: {
        prop: 'value',
        event: 'confirm',
    },

    props: {
        value: {
            // 传入日期值
            required: true,
            validator(value) {
                return !value;
            },
        },
        columns: {
            required: true,
            type: Array,
        },
    },

    data() {
        return {
            visible: false,
            loading: false,
        };
    },

    computed: {
       
    },

    created() {
        // this.getActivity();
    },

    methods: {
        showActivityPicker() {
            this.visible = true;
        },

        async getActivity() {
            this.loading = true;
            const activityList = (await getRebateActivity({ systemId: 1 })) || [];
            let list = activityList.map(item => ({ text: item.title, id: item.id }));
            this.loading = false;
            this.columns = list;
        },

        // 确认
        onConfirm(currValue) {
            this.$emit('confirm', currValue);
            this.visible = false;
        },
    },
};

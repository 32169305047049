/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 19:45:57
 * @Description:
 * @FilePath: /frontend/src/components/rebate/rebate-table/index.js
 */
import Rmb from "@/components/common/rmb/index.vue";
import EmptyList from "@/components/common/empty-list/index.vue";

export default {
  name: "rebate-table",
  components: { Rmb, EmptyList },
  props: {
    hasArrow: {
      //  是否需要展示箭头
      default: false,
      required: false,
    },
    columns: {
      type: Array,
      required: true,
      default: () => {
        return [
          {
            label: "代理名称",
            key: "name",
          },
          {
            label: "金额",
            isRmb: true,
            key: "amount",
          },
          {
            label: "百分比",
            isPercent: true,
            key: "percent",
          },
        ];
      },
    },
    datasource: {
      type: Array,
      required: true,
      default: () => {
        return [
          { name: "chengdafa ", amount: 1999, percent: 87 },
          { name: "shaly", amount: 18899, percent: 8 },
        ];
      },
    },
    hasSumHeader: {
      //  是否有展示小计汇总数据
      required: false,
      default: false,
    },
  },
  computed: {
    computedStyle() {
      const len = this.columns.length;
      const percent = 100 / len;
      return {
        width: percent + "%",
      };
    },
  },
  methods: {
    onClickTr(row) {
      this.$emit("click-row", row);
    },
  },
};

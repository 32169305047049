/**
 * 日期选择器（配合弹窗）
 */
export default {
  name: 'date-picker',

  model: {
    prop: 'value',
    event: 'confirm'
  },

  props: {
    value: { // 值
      type: Date,
      default: new Date()
    }
  },
  
  data () {
    return {
      visible: false, // 显示/隐藏
      localValue: null, // 当前值
    }
  },

  watch: {
    value: { // 与传入props同步
      immediate: true,
      handler () {
        this.localValue = this.value
      }
    }
  },

  methods: {
    // 打开
    show () {
      this.visible = true
    },

    // 关闭
    close () {
      this.visible = false
    },

    // 确认
    onConfirm (currValue) {
      console.log(currValue,currValue.getFullYear())
      this.$emit('confirm', currValue)
      this.close()
    }
  }
}

import { HttpV1 as HTTP } from '@/request';

/**
 * 获取用户信息
 */
export async function getUserInfoByDate({ userId, endTime, startTime, systemId }) {
    const data = await HTTP.get(`/agent/new-rebate/userInfo`, {
        userId,
        endTime,
        startTime,
        systemId,
    });
    return data;
}

/**
 * 获取业绩首页信息
 */
export async function getRebateSum({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/firstPage`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

/**
 * 获取团队销售折扣
 */
export async function getTeamPurch({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/teamDiscount`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

/**
 * 获取团队销售折扣明细
 */
export async function getTeamPurchDetail({ userId, endTime, startTime }) {
    const data = await HTTP.get(`/agent/rebate/teamDiscount/detail`, {
        userId,
        endTime,
        startTime,
    });
    return data;
}

/**
 * 获取支出
 */
export async function getOutPay({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/out-pay`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

/**
 * 获取团队达标业绩
 */
export async function getTeamMarkDetail({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/teamMark-detail`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

/**
 * 获取推荐奖
 */
export async function getRecommendDetail({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/recommend-detail`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

/**
 * 获取折扣发放奖励
 */
export async function getPurchAward({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/award-out`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

/**
 * 获取折扣发放明细
 */
export async function getPurchAwardDetail({ userId, endTime, startTime, systemId, levelId, actId }) {
    const data = await HTTP.get(`/agent/new-rebate/discount-detail`, {
        userId,
        endTime,
        startTime,
        systemId,
        levelId,
        actId,
    });
    return data;
}

export async function getRebateActivity({ systemId }) {
    const data = await HTTP.get(`/agent/new-rebate/activity`, {
        systemId,
    });
    return data;
}

export async function getRebateSystems() {
    const data = await HTTP.get(`/agent/new-rebate/systems`);
    return data;
}

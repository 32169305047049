import { getRebateSystems } from '@/service/rebate/novNewRebate';

/**
 * 选择查询业绩的时间范围
 * 以月份为单位
 */
export default {
    name: 'system-picker',

    components: {},

    model: {
        prop: 'value',
        event: 'confirm',
    },

    props: {
        value: {
            // 传入日期值
            required: true,
            validator(value) {
                return !value;
            },
        },
    },

    data() {
        return {
            visible: false,
            columns: [],
            loading: false,
        };
    },

    computed: {
        defaultIndex() {
            const { value, columns } = this;
            var index = columns.findIndex(item => item === value);
            return index;
        },
    },

    created() {
        this.getSystems();
    },

    methods: {
        showActivityPicker() {
            this.visible = true;
        },

        async getSystems() {
            this.loading = true;
            const systemList = (await getRebateSystems()) || [];
            let list = systemList.map(item => ({ text: item.name, id: item.id }));
            this.loading = false;
            this.columns = list;
        },

        // 确认
        onConfirm(currValue) {
            this.$emit('confirm', currValue);
            this.visible = false;
        },
    },
};
